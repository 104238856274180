const { clippingParents } = require("@popperjs/core");
// let hasSubmitted = false;

$(function () {
//original from: custom.js
//'use strict';

    $('.btn-submit-export-form').hide();
    let formExport = sessionStorage.getItem('formExport')
    let feedbackText = '<div class="invalid-feedback d-block">This value should not be blank.</div>';
    let feedbackMail = '<div class="invalid-feedback d-block">This value should be a valid email.</div>';

    //SELECT DDL/
    function changeSelectStyle(selectName, toggleDiv) {
        if(!$(selectName).val()) {
            $(toggleDiv).show();
        } else {
            $(toggleDiv).hide();
            $(selectName).removeAttr('style');
        }
    }

    $('#export_notifyAddressSelect').on('change', function () {
        changeSelectStyle('#export_notifyAddressSelect', '.notifyAddress-ifNotChoosen');
    });

    $('#export_packingExport_inspectService').on('change', function () {
        changeSelectStyle('#export_packingExport_inspectService', '.inspectionService-ifNotChoosen');
    });

    $('#export_inspectionAddressSelect').on('change', function () {
        changeSelectStyle('#export_inspectionAddressSelect', '.inspectionAddress-ifNotChoosen');
    });

    $('#export_packingExport_inspectRequired input').on('change', function() {
        if ($(this).val() == 1) {
            $('#PanInspection').collapse('show').parent().removeClass('border-0');
        }
        else {
            $('#PanInspection').collapse('hide').parent().addClass('border-0');
        }
    });
  
    $('input:radio[name="export[packingExport][originCertificate]"]').on('click', function () {
        removeRadioInvalidFeedback('input:radio[name="export[packingExport][originCertificate]"]');  
    });

    $('input:radio[name="export[packingExport][billOfLading]"]').on('click', function () {
        removeRadioInvalidFeedback('input:radio[name="export[packingExport][billOfLading]"]');  
    });

    $('input:radio[name="export[packingExport][inspectRequired]"]').on('click', function () {
        removeRadioInvalidFeedback('input:radio[name="export[packingExport][inspectRequired]"]');  
    });

    $('input:radio[name="export[packingExport][fumingation]"]').on('click', function () {
        removeRadioInvalidFeedback('input:radio[name="export[packingExport][fumingation]"]');  
    });

    $('input:radio[name="export[packingExport][waiverRequired]"]').on('click', function () {
        removeRadioInvalidFeedback('input:radio[name="export[packingExport][waiverRequired]"]');   
    });


    $('[id$="Address_firstname"], '
    +'[id$="Address_lastname"], '
    +'[id$="Address_company"], '
    +'[id$="Address_phoneNumber"], '
    +'[id$="Address_country"], '
    +'#export_packingExport_ownInspectService, '
    +'#export_packingExport_documentEmail'
    ).on('input', function() {
       $(this).removeAttr('style');
       if($(this).next().hasClass('invalid-feedback')) {
            $(this).next().remove();
        }
   });

    $('[id$="Address_firstname"], '
    +'[id$="Address_lastname"], '
    +'[id$="Address_company"], '
    +'[id$="Address_phoneNumber"], '
    +'[id$="Address_country"]'
    ).on('input', function() {
        $('[id$="AddressSelect"]').removeAttr('style');
    });

    $('#export_packingExport_ownInspectService').on('input', function() {
        $('[id$="inspectService"]').removeAttr('style');
    });

    //https://stackoverflow.com/questions/2507030/email-validation-using-jquery
    //https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    function isValidEmailAddress(emailAddress) {
        let pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    }

    function removeRadioInvalidFeedback(radioName) {
        if($(radioName).parent().parent().next().hasClass('invalid-feedback')){
            $(radioName).parent().parent().removeAttr('style');
            $(radioName).parent().parent().next().remove();
        }    
    }
    
    function addInvalidFeedback(fieldName, feedbackText) {
        fieldName.css("border", "1px solid red");
        if (fieldName.next().hasClass('invalid-feedback')){
            fieldName.next().remove();
        }
        $( feedbackText ).insertAfter( fieldName );
    }

    function showInvalidField(fieldName){
        $(fieldName).css({"color": "red", "border": "1px solid red"});
    }

    function ifNoAddressSelected() {
        let notifyAddress_firstname=$('#export_notifyAddress_firstname');
        let notifyAddress_lastname=$('#export_notifyAddress_lastname');
        let notifyAddress_company=$('#export_notifyAddress_company');
        let notifyAddress_phoneNumber=$('#export_notifyAddress_phoneNumber');
        let notifyAddress_country=$('#export_notifyAddress_country');
        let markValidIfNoAddress = true;

        if (notifyAddress_firstname.val() == "" || typeof notifyAddress_firstname.val() == "undefined") { 
            addInvalidFeedback(notifyAddress_firstname, feedbackText);
            if (markValidIfNoAddress) markValidIfNoAddress = false;
        }    
          
        if (notifyAddress_lastname.val() == "" || typeof notifyAddress_lastname.val() == "undefined") {   
            addInvalidFeedback(notifyAddress_lastname, feedbackText);        
            if (markValidIfNoAddress) markValidIfNoAddress = false;
        }

          if (notifyAddress_company.val() == "" || typeof notifyAddress_company.val() == "undefined") {    
            addInvalidFeedback(notifyAddress_company, feedbackText);         
            if (markValidIfNoAddress) markValidIfNoAddress = false;
          }

          if (notifyAddress_phoneNumber.val() == "" || typeof notifyAddress_phoneNumber.val() == "undefined") {
            addInvalidFeedback(notifyAddress_phoneNumber, feedbackText);          
            if (markValidIfNoAddress) markValidIfNoAddress = false;
          }

        if(!notifyAddress_country.find(':selected').val()) {
            addInvalidFeedback(notifyAddress_country, feedbackText);
            if (markValidIfNoAddress) markValidIfNoAddress = false;
        }

        return markValidIfNoAddress
    }

    function ifNoinspectServiceSelected() {
        let packingExport_ownInspectService=$('#export_packingExport_ownInspectService');
        let markValidIfNoAddress = true;

        if (packingExport_ownInspectService.val() == "" || typeof packingExport_ownInspectService.val() == "undefined") { 
            addInvalidFeedback(packingExport_ownInspectService, feedbackText);
            if (markValidIfNoAddress) markValidIfNoAddress = false;
        }    

        return markValidIfNoAddress
    }

    function ifNoinspectAddressSelected() {
         let inspectionAddress_firstname=$('#export_inspectionAddress_firstname');
         let inspectionAddress_lastname=$('#export_inspectionAddress_lastname');
         let inspectionAddress_company=$('#export_inspectionAddress_company');
         let inspectionAddress_phoneNumber=$('#export_inspectionAddress_phoneNumber');
         let inspectionAddress_country=$('#export_inspectionAddress_country');
         let markValidIfNoAddress = true;

        if (inspectionAddress_firstname.val() == "" || typeof inspectionAddress_firstname.val() == "undefined") {   
            addInvalidFeedback(inspectionAddress_firstname, feedbackText);        
            if (markValidIfNoAddress) markValidIfNoAddress = false;
        }

        if (inspectionAddress_lastname.val() == "" || typeof inspectionAddress_lastname.val() == "undefined") {   
            addInvalidFeedback(inspectionAddress_lastname, feedbackText);        
            if (markValidIfNoAddress) markValidIfNoAddress = false;
        }

          if (inspectionAddress_company.val() == "" || typeof inspectionAddress_company.val() == "undefined") {    
            addInvalidFeedback(inspectionAddress_company, feedbackText);         
            if (markValidIfNoAddress) markValidIfNoAddress = false;
          }

          if (inspectionAddress_phoneNumber.val() == "" || typeof inspectionAddress_phoneNumber.val() == "undefined") {
            addInvalidFeedback(inspectionAddress_phoneNumber, feedbackText);          
            if (markValidIfNoAddress) markValidIfNoAddress = false;
          }

        if(!inspectionAddress_country.find(':selected').val()) {
            addInvalidFeedback(inspectionAddress_country, feedbackText);
            if (markValidIfNoAddress) markValidIfNoAddress = false;
        } 

        return markValidIfNoAddress
    }

    function ValidatenotifyAddress() {
        let isValid = true;    
        let notifyAddressSelect=$('#export_notifyAddressSelect').closest('select').find(':selected').val();
        if(!notifyAddressSelect) {
            isValid= ifNoAddressSelected();
            if(isValid){
                changeSelectStyle('#export_notifyAddressSelect', '.notifyAddress-ifNotChoosen');
            } else {
                showInvalidField('#export_notifyAddressSelect');
                $(".notifyAddress-ifNotChoosen").show();
            }
        }
        return isValid;
    }

    function scrollToAnchor(aid, e) {
        let aTag = $("a[name='"+ aid +"']");
        $('html,body').animate({scrollTop: aTag.offset().top},'slow');

        //causing error makes everything to work
        aTag.focus();
    }

    function validateExportItem(e) {
        let wasValid= ValidatenotifyAddress();
          if(!wasValid) {
            findFirstErrorOcurrence(e);
          } else {
            
            $('#CollLabeling').collapse("show");
            $('#export_notifyAddressSelect').removeAttr('style');
            $('.validate-export-item').hide();
          }
    }

    function validateLabellingItem() {
        $('#CollshippingService').collapse("show");
        $('.validate-labelling-item').hide();         
    }

    function validateShippingService() {
        $('#CollcertificateOrigin').collapse("show");
        $('.validate-shippingService-item').hide();
        
       if($('.validate-shippingService-item').hasClass( "inland" )){
            $('.btn-submit-export-form').show();
       }
    }

    function validateCertificateOrigin() {
        if ($('input:radio[name="export[packingExport][originCertificate]"]:checked').length > 0) {
            $('#ColldescriptionLading').collapse("show");
            $('.validate-certificateOrigin-item').hide(); 
        } else {
            let export_packingExport_originCertificate=$('#export_packingExport_originCertificate');
                addInvalidFeedback(export_packingExport_originCertificate, feedbackText);
        } 
    }

    function validatedescriptionLading() {
        if ($('input:radio[name="export[packingExport][billOfLading]"]:checked').length > 0) {
            $('#ColldocumentEmail').collapse("show");
            $('.validate-descriptionLading-item').hide(); 
        } else {
            let export_packingExport_billOfLading=$('#export_packingExport_billOfLading');
                addInvalidFeedback(export_packingExport_billOfLading, feedbackText);
        } 
    }

    function validatedocumentEmail() {
        if( !isValidEmailAddress( $('#export_packingExport_documentEmail').val() ) ) { 
            addInvalidFeedback($('#export_packingExport_documentEmail'), feedbackMail);
        } else {         
                  
            $('#CollinspectionRequired').collapse("show");
             
           if ( $('input:radio[name="export[packingExport][inspectRequired]"]:checked').val() > 0 ) {              
            //If yes=1, inspection required
            $('#PanInspection').collapse('show');
           }
           
            $('.validate-documentEmail-item').hide(); 
        }

    }

    function validateinspectionRequired(e) {
        if ($('input:radio[name="export[packingExport][inspectRequired]"]:checked').length > 0) {          
            if ( $('input:radio[name="export[packingExport][inspectRequired]"]:checked').val() > 0 ) {              
                //If yes=1, inspection required
                let wasValid= ValidateInspectService();
                if(!wasValid) {
                    findFirstErrorOcurrence(e);                                  
                } else {

                    $('#CollpalletFumigation').collapse("show");
                    $('.validate-inspectionRequired-item').hide(); 
                } 

            } else {
                //If no=0, inspection required
                $('#CollpalletFumigation').collapse("show");
                $('.validate-inspectionRequired-item').hide();
            }

        } else {
            //No Radio Option selected
             let export_packingExport_inspectRequired=$('#export_packingExport_inspectRequired');
                 addInvalidFeedback(export_packingExport_inspectRequired, feedbackText);
        } 
    }

    function validatepalletFumigation() {
        if ($('input:radio[name="export[packingExport][fumingation]"]:checked').length > 0) {

            $('#CollwaiverRequired').collapse("show");
            $('.validate-inspectionRequired-item').hide(); 
            $('.validate-palletFumigation-item').hide(); 

            $('.confirm-waiverRequired-item').hide();
            $('.btn-submit-export-form').show();
    
        } else {
             let export_packingExport_fumingation=$('#export_packingExport_fumingation');
             addInvalidFeedback(export_packingExport_fumingation, feedbackText);
        } 
    }

    function validateWaiverRequired(){

        if ($('input:radio[name="export[packingExport][waiverRequired]"]:checked').length > 0) {
            $('.btn-submit-export-form').show();
            $('.confirm-waiverRequired-item').hide();
        } else {   
             let export_packingExport_waiverRequired=$('#export_packingExport_waiverRequired');
                 addInvalidFeedback(export_packingExport_waiverRequired, feedbackText);
        } 

    }

    function ValidateInspectService() {
        let isValid = true;
        let inspectServiceSelect=$('#export_packingExport_inspectService').closest('select').find(':selected').val();   
        let inspectAddressSelect=$('#export_inspectionAddressSelect').closest('select').find(':selected').val();  

        if(!inspectServiceSelect) {
            isValid= ifNoinspectServiceSelected();
            if(isValid){
                $('#export_packingExport_inspectService').removeAttr('style');
            } else {
                showInvalidField('#export_packingExport_inspectService');
                $(".inspectionService-ifNotChoosen").show();
            }          
        }

        if(!inspectAddressSelect) {
             isValid= ifNoinspectAddressSelected();
            if(isValid){
                $('#export_inspectionAddressSelect').removeAttr('style');
            } else {
                showInvalidField('#export_inspectionAddressSelect');
                $(".inspectionAddress-ifNotChoosen").show();
            }  
        }

        return isValid;
    }

    function findFirstErrorOcurrence(e) {
        //find first error occurrence and anchor it
        if($('#accordionCustomStep [style*="red"]').length > 0){
            let hook = $('#accordionCustomStep [style*="red"]').first().closest( ".hook" ).attr('name');
            scrollToAnchor(hook,e);
        }
    }

    $('.validate-export-item').on("click", function (e) {
        validateExportItem();
    });

    $('.validate-labelling-item').on("click", function (e) {
        validateExportItem();
        validateLabellingItem();
        findFirstErrorOcurrence(e);
      });

    $('.validate-shippingService-item').on("click", function (e) {
        validateExportItem();
        validateLabellingItem();
        validateShippingService();
        findFirstErrorOcurrence(e);
    });

    $('.validate-certificateOrigin-item').on("click", function (e) {
        validateExportItem();
        validateLabellingItem();
        validateShippingService();
        validateCertificateOrigin();
        findFirstErrorOcurrence(e);
       });

    $('.validate-descriptionLading-item').on("click", function (e) {
        validateExportItem();
        validateLabellingItem();
        validateShippingService();
        validateCertificateOrigin();
        validatedescriptionLading();
        findFirstErrorOcurrence(e); 
    });

    $('.validate-documentEmail-item').on("click", function (e) {   
        validateExportItem();
        validateLabellingItem();
        validateShippingService();
        validateCertificateOrigin();
        validatedescriptionLading();
        validatedocumentEmail();
        findFirstErrorOcurrence(e); 
    });

    $('.validate-inspectionRequired-item').on("click", function (e) {
        validateExportItem();
        validateLabellingItem();
        validateShippingService();
        validateCertificateOrigin();
        validatedescriptionLading();
        validatedocumentEmail();
        validateinspectionRequired(e);
       //  findFirstErrorOcurrence(e); 
    });   

    $('.validate-palletFumigation-item').on("click", function (e) {
        validateExportItem();
        validateLabellingItem();
        validateShippingService();
        validateCertificateOrigin();
        validatedescriptionLading();
        validatedocumentEmail();
        validateinspectionRequired(e);
        validatepalletFumigation();

         findFirstErrorOcurrence(e); 
  });

    $('.btn-submit-export-form').on("click", function (e) {
        //Evaluate if Origin is inland/ outland?
        validateExportItem();
        validateLabellingItem();
        validateShippingService();
        validateCertificateOrigin();
        validatedescriptionLading();
        validatedocumentEmail();
        validateinspectionRequired(e);
        validatepalletFumigation();
        validateWaiverRequired();
        findFirstErrorOcurrence(e); 
        formExportSubmit();
    });

    $('.btn-submit-address').on("click", function (e) {
        removeExportSubmit();
    });   

    function removeExportSubmit()
    {
        sessionStorage.removeItem('formExport');
    }

    function formExportSubmit()
    {
        //checkout address
        sessionStorage.setItem('formExport', 'true');
    }

   if(formExport) {
    //unklapp Alle
    //TODO:: Hide all continue button on the way back
    $('#CollLabeling').collapse("show");
    $('#CollshippingService').collapse("show");
    $('#CollcertificateOrigin').collapse("show");

    if($('.validate-shippingService-item').hasClass( "outland" )) {
        $('#ColldescriptionLading').collapse("show");
        $('#ColldocumentEmail').collapse("show");
        $('#CollinspectionRequired').collapse("show");
                
        if ( $('input:radio[name="export[packingExport][inspectRequired]"]:checked').val() > 0 ) {              
        //If yes=1, inspection required
            $('#PanInspection').collapse('show');
        }

        $('#CollpalletFumigation').collapse("show");
        $('#CollwaiverRequired').collapse("show");
    }

    $('button[class*="validate-"]').hide();
    $('.btn-submit-export-form').show();
   }
    
//EoF Custom Step Validation

    // $("#owl-product").owlCarousel({
    //     autoPlay: false,
    //     items: 3,
    //     stopOnHover: true,
    //     navigation: true,
    //     pagination: false,
    //     navigationText: ["<span class='glyphicon glyphicon-chevron-left'></span>", "<span class='glyphicon glyphicon-chevron-right'></span>"]
    // });

    $('.nav-tabs a').on("click", function (e) {
        e.preventDefault();
        $(this).tab('show');
    });


    let elemWidth, fitCount, varWidth = 0, ctr,
        $menu = $("ul#more-menu"), $collectedSet;

    ctr = $menu.children().length;
    $menu.children().each(function () {
        varWidth += $(this).outerWidth();
    });

    collect();
    $(window).resize(collect);

    function collect() {
        elemWidth = $menu.width();
        fitCount = Math.floor((elemWidth / varWidth) * ctr) - 1;

        if ((fitCount + 1) == ctr) {
            $('.nav.collect').hide();
        } else {
            $('.nav.collect').show();
        }

        $menu.children().css({"display": "block", "width": "auto"});
        $collectedSet = $menu.children(":gt(" + fitCount + ")");
        $("#submenu").empty().append($collectedSet.clone());
        $collectedSet.css({"display": "none", "width": "0"});
    }

//Dropdown carets custom asantys
//ToDo::NOT WORKING Properly
// if ($('.pdp-accord-toggle').attr('aria-expanded') === "true") {
// }
    $('.asantys-select').on("click", function (e) {
        if ($('.btn').attr('aria-expanded') === "true") {
            console.log('closed');
        } else {
            console.log('open');
        }

    });

    /* Initialize Range Slider */
    const $rangeSlider = $('.range-slider');

    $rangeSlider.each((index, elem) => {
        const $minInput = $(elem).siblings('.range-slider-input-min');
        const $maxInput = $(elem).siblings('.range-slider-input-max');
        const minInput = $minInput.get(0);
        const maxInput = $maxInput.get(0);
        const minOutput = $(elem).siblings('.range-slider-output').find('.range-slider-output-min').get(0);
        const maxOutput = $(elem).siblings('.range-slider-output').find('.range-slider-output-max').get(0);
        const hasSingleHandle = false;

        const start = (hasSingleHandle) ? [parseFloat(maxInput.value)] : [parseFloat(minInput.value), parseFloat(maxInput.value)];
        const defaultValues = (hasSingleHandle) ? [parseFloat($maxInput.data('default-value-max'))] : [parseFloat($minInput.data('default-value-min')), parseFloat($maxInput.data('default-value-max'))];
        const connect = (hasSingleHandle) ? 'lower' : true;
        const step = $(elem).data('stepCount');
        const unit = $(elem).data('unit') ? $(elem).data('unit') : '';
        const range = {min: [parseFloat(minInput.min)], max: [parseFloat(maxInput.max)]};

        const defaultFormat = wNumb({
            decimals: 0,
            // mark: ',',
            thousand: '.',
            suffix: ` ${unit}`,
        });
        const priceFormat = wNumb({
            decimals: 2,
            mark: ',',
            suffix: ' €',
            encoder(value) {
                return value / 100;
            },
            decoder(value) {
                return value * 100;
            },
        });

        noUiSlider.create(elem, {
            start,
            connect,
            step,
            range,
            tooltips: (unit === '€') ? priceFormat : defaultFormat,
            pips: {
                mode: 'positions',
                values: [0, 100],
                density: 100,
                format: (unit === '€') ? priceFormat : defaultFormat
            }
        });

        elem.noUiSlider.on('update', (values, handle) => {
            const value = values[handle];

            if (handle || hasSingleHandle) { // secondHandle (handle === 1)
                maxInput.value = value;
                if (maxOutput)
                    maxOutput.innerHTML = (unit === '€') ? priceFormat.to(parseFloat(value)) : defaultFormat.to(parseFloat(value));
            } else { // firstHandle (handle === 0)
                minInput.value = value;
                if (minOutput)
                    minOutput.innerHTML = (unit === '€') ? priceFormat.to(parseFloat(value)) : defaultFormat.to(parseFloat(value));
            }
        });

        minInput.addEventListener('change', (e) => {
            if (hasSingleHandle) {
                elem.noUiSlider.set(e.currentTarget.value);
            } else {
                elem.noUiSlider.set([e.currentTarget.value, null]);
            }
        });

        maxInput.addEventListener('change', (e) => {
            if (hasSingleHandle) {
                elem.noUiSlider.set(e.currentTarget.value);
            } else {
                elem.noUiSlider.set([null, e.currentTarget.value]);
            }
        });
    });

    /*Carousel*/
    $("#detailProduct").owlCarousel({
        singleItem: true,
        navigation: true,
        navigationText: ["<i class='carousel-control-prev-icon y'></i>", "<i class='carousel-control-next-icon'></i>"],
        autoPlay: false,
        navSpeed: 400,
        pagination: true,
        paginationNumbers: false
    });

    /*ToDo: Update*/
    $("#owl-product").owlCarousel({
        autoPlay: false,
        stopOnHover: true,
        navigation: true,
        pagination: false,
        responsive: {
            0: {
                items: 1
            },
            1024: {
                items: 2
            },
            1200: {
                items: 3
            },
            1920: {
                items: 6
            },
        },
        navigationText: ["<span class='carousel-control-prev-icon'></span>", "<span class='carousel-control-next-icon'></span>"]
    });

    // Price Unit Dropdown/Select
    $('.unit-prices .dropdown-item').on('click', function (e) {
        let $dropdownItem = $(e.currentTarget),
            $dropdown = $dropdownItem.closest('.dropdown');

        $dropdown.find('.dropdown-toggle').html($dropdownItem.html());

        // fill hidden select
        $dropdown.closest('form').find('.cs-unit-selector').val($dropdownItem.data('unit-value'));
    });


    $(".selectBox").on("click", function (e) {
        $(this).toggleClass("show");
        let dropdownItem = e.target;
        let container = $(this).find(".selectBox__value");

        // container.html($(dropdownItem).parent().html());
        // container.html($(dropdownItem).closest(".price-wrapper").html());

        // $(dropdownItem).closest( ".price-wrapper" ).addClass("active");
    });

    // $(".selectBox .dropdown-item").on("click", function() {
    //   $(".selectBox .dropdown-item > div" ).removeClass('active');

    // });

    $('body:not(.page-faq) .collapse').on('shown.bs.collapse', function (e) {
        let $card = $(this).closest('.accordion-item');
        let $open = $($(this).data('parent')).find('.collapse.show');

        let additionalOffset = 0;
        if ($card.prevAll().filter($open.closest('.accordion-item')).length !== 0) {
            additionalOffset = $open.height();
        }
        $('html,body').animate({
            scrollTop: $card.offset().top - additionalOffset
        }, 20);
    });

    // Registrierungs-Form - Doppelklick verhindern - doppelte Anmeldung verhindern
    $('#form_register_customer_submit').on('click', function(e) {
        e.preventDefault();

        $(this).attr('disabled', true);
        $('#form_registration_form').submit();
    });

    // KontaktFormular - mehrfaches Absenden verhindern
    $('#formbuilder_2_field_13250').on('click', function(e) {
        e.preventDefault();
        $(this).attr('disabled', true);
        $('#form_contact_id').submit();
    });

    /*
    // Vorbereitung add-to-cart als ajax-call
    $('#form-add-toCart-button').on('click', function(e) {
        e.preventDefault();
        let $form = $('#render-add-to-cart-form').find('form');
        let $url = $form.prop('action');

        console.log('click on add-to-cart');
        // console.log($url);
        // console.dir($form.serialize());

        $.ajax({
            url: $url,
            method: 'post',
            data: $form.serialize(),
        }).success(() => {
            // $form.submit();
            console.log('yep');
        }).done((response) => {
        });

    });
*/
    console.log("ready!  ");
});
